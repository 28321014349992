import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2130 3192 l0 -419 23 -20 c23 -22 103 -73 112 -73 4 0 4 4 1 9 -3 4
-12 77 -21 162 -8 85 -19 192 -25 239 -5 47 -17 176 -26 287 -17 192 -19 204
-41 218 l-23 15 0 -418z"/>
<path d="M2330 3025 l0 -385 48 -24 c60 -30 97 -42 88 -28 -6 10 -25 183 -72
642 -15 151 -24 180 -56 180 -4 0 -8 -173 -8 -385z"/>
<path d="M2525 3300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2530 2883 l1 -348 70 -42 c44 -27 69 -37 65 -27 -3 9 -10 55 -16
102 -9 82 -22 188 -34 292 -3 25 -13 112 -22 195 -16 144 -26 173 -56 175 -5
0 -8 -156 -8 -347z"/>
<path d="M2963 2301 c-29 -26 -76 -59 -105 -74 -46 -24 -55 -34 -69 -75 -9
-26 -19 -55 -23 -64 -7 -15 -4 -16 31 -6 59 16 168 76 218 120 42 37 45 42 30
58 -8 9 -15 21 -15 27 0 6 -7 16 -15 23 -12 10 -13 16 -4 26 21 25 5 14 -48
-35z"/>
<path d="M2148 2239 c-15 -8 -18 -24 -18 -89 0 -68 3 -81 19 -90 20 -10 361
-15 361 -5 0 13 -34 72 -53 93 -19 20 -30 22 -119 22 -96 0 -98 0 -98 23 0 45
-51 70 -92 46z"/>
<path d="M1870 1575 c0 -68 2 -75 20 -75 16 0 20 7 21 33 0 30 1 31 11 10 6
-13 16 -23 23 -23 7 0 17 10 23 23 10 21 11 20 11 -10 1 -26 5 -33 21 -33 18
0 20 7 20 75 0 66 -2 75 -18 75 -12 0 -25 -14 -38 -41 l-18 -41 -20 41 c-12
25 -27 41 -38 41 -16 0 -18 -9 -18 -75z"/>
<path d="M2040 1575 c0 -73 1 -75 25 -75 19 0 25 5 25 23 l1 22 18 -22 c11
-13 28 -23 40 -23 19 0 19 1 -4 42 -24 42 -24 42 -4 69 24 34 24 39 0 39 -10
0 -26 -10 -34 -22 l-16 -23 0 23 c-1 17 -7 22 -26 22 -24 0 -25 -2 -25 -75z"/>
<path d="M2180 1575 l0 -75 55 0 c42 0 55 3 55 15 0 10 -10 15 -30 15 -20 0
-30 5 -30 15 0 10 10 15 30 15 20 0 30 5 30 15 0 10 -10 15 -30 15 -20 0 -30
5 -30 15 0 10 10 15 30 15 20 0 30 5 30 15 0 12 -13 15 -55 15 l-55 0 0 -75z"/>
<path d="M2310 1575 l0 -75 55 0 c42 0 55 3 55 15 0 10 -10 15 -30 15 -20 0
-30 5 -30 15 0 10 10 15 30 15 20 0 30 5 30 15 0 10 -10 15 -30 15 -20 0 -30
5 -30 15 0 10 10 15 30 15 20 0 30 5 30 15 0 12 -13 15 -55 15 l-55 0 0 -75z"/>
<path d="M2465 1600 c14 -27 25 -61 25 -75 0 -18 5 -25 20 -25 16 0 20 7 20
30 0 17 9 48 20 70 25 48 25 50 0 50 -13 0 -25 -10 -32 -27 l-11 -28 -10 28
c-8 19 -18 27 -34 27 l-23 0 25 -50z"/>
<path d="M2590 1575 l0 -78 47 6 c53 6 73 25 73 72 0 47 -20 66 -73 72 l-47 6
0 -78z m75 0 c0 -20 -6 -31 -17 -33 -15 -3 -18 4 -18 33 0 29 3 36 18 33 11
-2 17 -13 17 -33z"/>
<path d="M2762 1593 c-11 -32 -23 -66 -27 -75 -5 -14 -2 -18 13 -18 12 0 24 5
27 10 3 6 17 10 30 10 13 0 27 -4 30 -10 3 -5 15 -10 27 -10 15 0 18 4 13 18
-4 9 -16 43 -27 75 -16 47 -24 57 -43 57 -19 0 -27 -10 -43 -57z m58 -35 c0
-5 -7 -8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
<path d="M2900 1630 c0 -13 7 -20 20 -20 18 0 20 -7 20 -55 0 -48 2 -55 20
-55 18 0 20 7 20 55 0 48 2 55 20 55 13 0 20 7 20 20 0 18 -7 20 -60 20 -53 0
-60 -2 -60 -20z"/>
<path d="M3058 1575 l-26 -75 24 0 c13 0 24 5 24 10 0 6 14 10 30 10 17 0 30
-4 30 -10 0 -5 9 -10 21 -10 23 0 22 2 -13 98 -15 41 -24 52 -41 52 -18 0 -27
-13 -49 -75z m62 -11 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14
6 -3 10 -13 10 -21z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
